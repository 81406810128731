import request from '@/utils/request'

export function getGoogleSku (data) {
  return request({
    url: '/pay/getGoogleSku',
    data
  })
}

export function getAppleSku (data) {
  return request({
    url: '/pay/getAppleSku',
    data
  })
}

export function getIOSApplicationIdList (data) {
  return request({
    url: '/pay/getIOSApplicationIdList',
    data
  })
}
export function getStripeSku (data) {
  return request({
    url: '/pay/getStripeSku',
    data
  })
}
export function getPaySku (data) {
  return request({
    url: '/pay/getPaySku',
    data
  })
}

export function synchronGoogleSku (data) {
  return request({
    url: '/pay/synchronGoogleSku',
    data
  })
}
export function setZoneGoodsStatus (data) {
  return request({
    url: '/pay/setZoneGoodsStatus',
    data
  })
}
export function setZoneGoods (data) {
  return request({
    url: '/pay/setZoneGoods',
    data
  })
}
export function getZoneGoodsList (data) {
  return request({
    url: '/pay/getZoneGoodsList',
    data
  })
}
export function nationAreaList (data) {
  return request({
    url: '/user/nationAreaList',
    data
  })
}
/**
 *
 * @param {*} params {goods_type : number , app_key: string}
 * @returns
 */
export function getGoods (params) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'get',
    url: 'admin/goods',
    params
  })
}

/**
 *
 * @param {*} params {goods_type : number , app_key: string}
 * @returns
 */
export function getSubscriptionProducts (params) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'get',
    url: 'admin/subscription-products',
    params
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function createOpenGood (data) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'post',
    url: 'admin/subscription-products',
    data
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function createGood (data) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'post',
    url: 'admin/goods',
    data
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function updateGood (data) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'put',
    url: `admin/goods/${data.goods_id}`,
    data
  })
}
